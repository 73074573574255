









































































import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';

import BaseDialog from '@/components/BaseDialog.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import ImageUploader from '@/components/ImageUploader.vue';

import {
  createScheduleStream,
  getStreamersList,
  updateScheduleStream
} from '@/api/CopyStake';
import {
  IStreamerListResponse,
  TScheduleStream,
  TStreamer,
  ValidationRules
} from '@/api/schema';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import { objectToBlob } from '@/helpers/blobHelper';
import * as vr from '@/helpers/validation';

const maxFileSize = 1024 * 400;
const imageRequirements = `Image aspect ratio must be 16:9 (recommended dimensions is 640 x 360 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;

export default {
  name: 'CopyStakeStreamingScheduleDialog',
  components: {
    BaseDialog,
    ImageUploader,
    VExtendedDataPicker
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    scheduleStream: {
      type: Object as PropType<TScheduleStream>,
      default: (): any => ({})
    }
  },
  data(): any {
    return {
      formData: {
        bannerImg: null,
        streamerId: '',
        dateOfStream: ''
      },
      imageRequirements,
      loading: {
        loadImage: false,
        loadStreamers: false,
        upload: false
      },
      uploadImageFile: null,
      maxFileSize,
      isFormValid: false,
      formKey: Date.now(),
      streamersList: null,
      fileInputKey: 1
    };
  },
  watch: {
    value(): void {
      this.formKey = Date.now();
      this.formData = {
        ...this.scheduleStream
      };

      if (!this.streamersList) {
        this.getStreamersData();
      }
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    rules(): ValidationRules {
      return {
        bannerImg: [
          (v) => !!v || imageRequirements,
          (v) => vr.fileMaxSize(v, maxFileSize, imageRequirements)
        ],
        streamerId: [vr.required],
        dateOfStream: [vr.required]
      };
    },
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    isEdit(): boolean {
      return !!this.scheduleStream?.id;
    },
    submitText(): string {
      return this.isEdit ? 'Update' : 'Create';
    },
    maxWidth(): string {
      return `${this.data?.maxWidth || 580}px`;
    }
  },
  methods: {
    getStreamersData(): void {
      if (!this.operatorId) return;

      this.loading.loadStreamers = true;

      getStreamersList({
        size: 1000,
        page: 0,
        operatorId: this.operatorId
      })
        .then((data: IStreamerListResponse) => {
          this.streamersList = data.content.map(
            ({ nickname, id }: TStreamer) => ({
              text: nickname,
              value: id
            })
          );
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally(() => {
          this.loading.loadStreamers = false;
        });
    },
    inputFileHandler(file: Blob): void {
      this.uploadImageFile = file;
    },
    async submitHandler(): Promise<void> {
      try {
        await this.$refs.form.validate();

        if (!this.isFormValid) return;

        this.loading.upload = true;

        const dateOfStream = DateTime.fromJSDate(
          new Date(this.formData.dateOfStream)
        );
        const formData = new FormData();

        formData.append(
          'scheduleData',
          objectToBlob({
            streamerId: this.formData.streamerId,
            dateOfStream: dateOfStream.toFormat('yyyy-MM-dd HH:mm:ss')
          })
        );

        formData.append('image', this.uploadImageFile);

        this.isEdit
          ? await updateScheduleStream(this.scheduleStream.id, formData)
          : await createScheduleStream(formData);

        this.$toast.success('Stream has been successfully scheduled');

        this.close();
        this.$emit('submit');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.loading.upload = false;
      }
    },
    close(): void {
      this.showDialog = false;
      this.$emit('close');
    }
  }
};
